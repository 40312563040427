import { Form } from "antd";
import React, { useEffect, useState } from "react";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import { HomeWrapped } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  getHomeContentPageContentRequest,
  postHomeContentPageContentRequest,
} from "../../redux/contentManagementSlice";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";
import VideoCard from "../../components/shared/VideoCard";

const HomeContentPage = () => {
  const dispatch = useDispatch();
  const { homeContentPageData, homeContentPageStatus, homeContentPageError } =
    useSelector((state) => state.contentManagement);
  const [form] = Form.useForm();
  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();
  // Banner Image Home Page
  const [bannerSectionImageUrl, setBannerSectionImageUrl] = useState();
  const [bannerSectionImageFile, setBannerSectionImageFile] = useState();
  // Discover Image Home Page
  const [discoverSectionImageUrl, setDiscoverSectionImageUrl] = useState();
  const [discoverSectionImageFile, setDiscoverSectionImageFile] = useState();
  // Quickly Image Home Page
  const [quicklySectionImageUrl, setQuicklySectionImageUrl] = useState();
  const [quicklySectionImageFile, setQuicklySectionImageFile] = useState();
  
  // why Choose Section
  const [whyChooseSectionImageUrl, setWhyChooseSectionImageUrl] = useState();
  const [whyChooseSectionImageFile, setWhyChooseSectionImageFile] = useState();
  // Meet Providers Section Images
  const [meetProviderSectionFirstProviderThumbnailUrl, setMeetProviderSectionFirstProviderThumbnailUrl] = useState();
  const [meetProviderSectionFirstProviderThumbnailFile, setMeetProviderSectionFirstProviderThumbnailFile] = useState();
  const [meetProviderSectionSecondProviderThumbnailUrl, setMeetProviderSectionSecondProviderThumbnailUrl] = useState();
  const [meetProviderSectionSecondProviderThumbnailFile, setMeetProviderSectionSecondProviderThumbnailFile] = useState();
  const [meetProviderSectionThirdProviderThumbnailUrl, setMeetProviderSectionThirdProviderThumbnailUrl] = useState();
  const [meetProviderSectionThirdProviderThumbnailFile, setMeetProviderSectionThirdProviderThumbnailFile] = useState();
  const [meetProviderSectionFourthProviderThumbnailUrl, setMeetProviderSectionFourthProviderThumbnailUrl] = useState();
  const [meetProviderSectionFourthProviderThumbnailFile, setMeetProviderSectionFourthProviderThumbnailFile] = useState();
  const [meetProviderSectionFirstProviderVideoUrl, setMeetProviderSectionFirstProviderVideoUrl] = useState();
  const [meetProviderSectionFirstProviderVideoFile, setMeetProviderSectionFirstProviderVideoFile] = useState();
  const [meetProviderSectionSecondProviderVideoUrl, setMeetProviderSectionSecondProviderVideoUrl] = useState();
  const [meetProviderSectionSecondProviderVideoFile, setMeetProviderSectionSecondProviderVideoFile] = useState();
  const [meetProviderSectionThirdProviderVideoUrl, setMeetProviderSectionThirdProviderVideoUrl] = useState();
  const [meetProviderSectionThirdProviderVideoFile, setMeetProviderSectionThirdProviderVideoFile] = useState();
  const [meetProviderSectionFourthProviderVideoUrl, setMeetProviderSectionFourthProviderVideoUrl] = useState();
  const [meetProviderSectionFourthProviderVideoFile, setMeetProviderSectionFourthProviderVideoFile] = useState();
  useEffect(() => {
    dispatch(getHomeContentPageContentRequest(CONTENT_PAGES.HOME_PAGE));
  }, [dispatch]);

  useEffect(() => {
    if (homeContentPageData) {
      form.setFieldsValue({
        "detail[bannerSection][heading]": homeContentPageData?.contentPage?.detail?.bannerSection?.heading,
        "detail[bannerSection][description]": homeContentPageData?.contentPage?.detail?.bannerSection?.description,
        // Discover Section
        "detail[discoverSection][heading]": homeContentPageData?.contentPage?.detail?.discoverSection?.heading,
        "detail[discoverSection][subHeading]": homeContentPageData?.contentPage?.detail?.discoverSection?.subHeading,
        "detail[discoverSection][description]": homeContentPageData?.contentPage?.detail?.discoverSection?.description,
        "detail[discoverSection][firstCounterValue]": homeContentPageData?.contentPage?.detail?.discoverSection?.firstCounterValue,
        "detail[discoverSection][firstCounterText]": homeContentPageData?.contentPage?.detail?.discoverSection?.firstCounterText,
        "detail[discoverSection][secondCounterValue]": homeContentPageData?.contentPage?.detail?.discoverSection?.secondCounterValue,
        "detail[discoverSection][secondCounterText]": homeContentPageData?.contentPage?.detail?.discoverSection?.secondCounterText,
        "detail[discoverSection][thirdCounterValue]": homeContentPageData?.contentPage?.detail?.discoverSection?.thirdCounterValue,
        "detail[discoverSection][thirdCounterText]": homeContentPageData?.contentPage?.detail?.discoverSection?.thirdCounterText,
        "detail[discoverSection][forthCounterValue]": homeContentPageData?.contentPage?.detail?.discoverSection?.forthCounterValue,
        "detail[discoverSection][forthCounterText]": homeContentPageData?.contentPage?.detail?.discoverSection?.forthCounterText,
        "detail[discoverSection][buttonText]": homeContentPageData?.contentPage?.detail?.discoverSection?.buttonText,
        // Quickly Job Section
        "detail[quicklyJobSection][heading]": homeContentPageData?.contentPage?.detail?.quicklyJobSection?.heading,
        "detail[quicklyJobSection][description]": homeContentPageData?.contentPage?.detail?.quicklyJobSection?.description,
        "detail[quicklyJobSection][buttonText]": homeContentPageData?.contentPage?.detail?.quicklyJobSection?.buttonText,
        // Meet Providers Section
        "detail[meetOurProvidersSection][text]": homeContentPageData?.contentPage?.detail?.meetOurProvidersSection?.text,
        "detail[meetOurProvidersSection][heading]": homeContentPageData?.contentPage?.detail?.meetOurProvidersSection?.heading,
        "detail[meetOurProvidersSection][description]": homeContentPageData?.contentPage?.detail?.meetOurProvidersSection?.description,
        "detail[meetOurProvidersSection][firstProviderName]": homeContentPageData?.contentPage?.detail?.meetOurProvidersSection?.firstProviderName,
        "detail[meetOurProvidersSection][firstProviderJobTitle]": homeContentPageData?.contentPage?.detail?.meetOurProvidersSection?.firstProviderJobTitle,
        "detail[meetOurProvidersSection][secondProviderName]": homeContentPageData?.contentPage?.detail?.meetOurProvidersSection?.secondProviderName,
        "detail[meetOurProvidersSection][secondProviderJobTitle]": homeContentPageData?.contentPage?.detail?.meetOurProvidersSection?.secondProviderJobTitle,
        "detail[meetOurProvidersSection][thirdProviderName]": homeContentPageData?.contentPage?.detail?.meetOurProvidersSection?.thirdProviderName,
        "detail[meetOurProvidersSection][thirdProviderJobTitle]": homeContentPageData?.contentPage?.detail?.meetOurProvidersSection?.thirdProviderJobTitle,
        "detail[meetOurProvidersSection][fourthProviderName]": homeContentPageData?.contentPage?.detail?.meetOurProvidersSection?.fourthProviderName,
        "detail[meetOurProvidersSection][fourthProviderJobTitle]": homeContentPageData?.contentPage?.detail?.meetOurProvidersSection?.fourthProviderJobTitle,

        // Why Choose Section
        "detail[whyChooseSection][heading]": homeContentPageData?.contentPage?.detail?.whyChooseSection?.heading,
        "detail[whyChooseSection][description]": homeContentPageData?.contentPage?.detail?.whyChooseSection?.description,
        "detail[whyChooseSection][firstSubHeading]": homeContentPageData?.contentPage?.detail?.whyChooseSection?.firstSubHeading,
        "detail[whyChooseSection][firstSubDescription]": homeContentPageData?.contentPage?.detail?.whyChooseSection?.firstSubDescription,
        "detail[whyChooseSection][secondSubHeading]": homeContentPageData?.contentPage?.detail?.whyChooseSection?.secondSubHeading,
        "detail[whyChooseSection][secondSubDescription]": homeContentPageData?.contentPage?.detail?.whyChooseSection?.secondSubDescription,
        "detail[whyChooseSection][thirdSubHeading]": homeContentPageData?.contentPage?.detail?.whyChooseSection?.thirdSubHeading,
        "detail[whyChooseSection][thirdSubDescription]": homeContentPageData?.contentPage?.detail?.whyChooseSection?.thirdSubDescription,
        "detail[whyChooseSection][buttonText]": homeContentPageData?.contentPage?.detail?.whyChooseSection?.buttonText,

        // Testimonial Section
        "detail[testimonialsSection][heading]": homeContentPageData?.contentPage?.detail?.testimonialsSection?.heading,
        "detail[testimonialsSection][description]": homeContentPageData?.contentPage?.detail?.testimonialsSection?.description,

        //Seo Values Set
        meta_title: homeContentPageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description: homeContentPageData?.contentPage?.seo_keywords?.meta_description ?? "",
        is_canonical: homeContentPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
        robots_meta: homeContentPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author: homeContentPageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type: homeContentPageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title: homeContentPageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description: homeContentPageData?.contentPage?.seo_keywords?.og_description ?? "",
        og_image_width: homeContentPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
        og_image_height: homeContentPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
        og_url: homeContentPageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name: homeContentPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
        og_locale: homeContentPageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate: homeContentPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
        twitter_card: homeContentPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
        twitter_site: homeContentPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
        twitter_title: homeContentPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
        twitter_description: homeContentPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
        twitter_url: homeContentPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setBannerSectionImageUrl(homeContentPageData?.contentPage?.contentImages?.bannerSectionImage ?? BannerImage);
      setDiscoverSectionImageUrl(homeContentPageData?.contentPage?.contentImages?.discoverSectionImage ?? BannerImage);
      setQuicklySectionImageUrl(homeContentPageData?.contentPage?.contentImages?.quicklySectionImage ?? BannerImage);
      setWhyChooseSectionImageUrl(homeContentPageData?.contentPage?.contentImages?.whyChooseSectionImage ?? BannerImage);
      setTwitterImageUrl(homeContentPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
      setOGImageUrl(homeContentPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);
      setMeetProviderSectionFirstProviderThumbnailUrl(homeContentPageData?.contentPage?.contentImages?.firstProviderThumbnail ?? BannerImage);
      setMeetProviderSectionSecondProviderThumbnailUrl(homeContentPageData?.contentPage?.contentImages?.secondProviderThumbnail ?? BannerImage);
      setMeetProviderSectionThirdProviderThumbnailUrl(homeContentPageData?.contentPage?.contentImages?.thirdProviderThumbnail ?? BannerImage);
      setMeetProviderSectionFourthProviderThumbnailUrl(homeContentPageData?.contentPage?.contentImages?.fourthProviderThumbnail ?? BannerImage);
      setMeetProviderSectionFirstProviderVideoUrl(homeContentPageData?.contentPage?.contentImages?.firstProviderVideo ?? null);
      setMeetProviderSectionSecondProviderVideoUrl(homeContentPageData?.contentPage?.contentImages?.secondProviderVideo ?? null);
      setMeetProviderSectionThirdProviderVideoUrl(homeContentPageData?.contentPage?.contentImages?.thirdProviderVideo ?? null);
      setMeetProviderSectionFourthProviderVideoUrl(homeContentPageData?.contentPage?.contentImages?.fourthProviderVideo ?? null);
    }
  }, [homeContentPageData, form]);

  const onUpdate = (values) => {
    const data = {
      ...values,

      name: 'Home Page',
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      "contentImages[bannerSectionImage]": bannerSectionImageFile ?? "",
      "contentImages[discoverSectionImage]": discoverSectionImageFile ?? "",
      "contentImages[quicklySectionImage]": quicklySectionImageFile ?? "",
      "contentImages[whyChooseSectionImage]": whyChooseSectionImageFile ?? "",
      "contentImages[firstProviderThumbnail]": meetProviderSectionFirstProviderThumbnailFile ?? "",
      "contentImages[secondProviderThumbnail]": meetProviderSectionSecondProviderThumbnailFile ?? "",
      "contentImages[thirdProviderThumbnail]": meetProviderSectionThirdProviderThumbnailFile ?? "",
      "contentImages[fourthProviderThumbnail]": meetProviderSectionFourthProviderThumbnailFile ?? "",
      "contentImages[firstProviderVideo]": meetProviderSectionFirstProviderVideoFile ?? "",
      "contentImages[secondProviderVideo]": meetProviderSectionSecondProviderVideoFile ?? "",
      "contentImages[thirdProviderVideo]": meetProviderSectionThirdProviderVideoFile ?? "",
      "contentImages[fourthProviderVideo]": meetProviderSectionFourthProviderVideoFile ?? "",
      _method: 'PUT'
    }
    dispatch(postHomeContentPageContentRequest({ data, id: CONTENT_PAGES.HOME_PAGE }))
  };

  return (
    <>
      {homeContentPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : homeContentPageStatus === STATUSES.ERROR ? (
        <div>{homeContentPageError}</div>
      ) : (
        <HomeWrapped>
          <div className="row">
            <div className="col-lg-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}
              >
                {/* <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >Home</h3>
                    <SaveButton title="Update" />
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-lg-9 col-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >Home</h3>
                    <SaveButton title="Update" />
                  </div>
                  <div className="col-lg-9 col-12">
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <TextAreas
                      labelText="Banner Section Description"
                      name="detail[bannerSection][description]"
                      placeText="Banner Section Description"
                    />
                    <BannerImageCard
                      title="Why Choose Section Image"
                      imageUrl={bannerSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setBannerSectionImageFile}
                      setImageUrl={setBannerSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Discover Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Discover Section Heading"
                      name="detail[discoverSection][heading]"
                      placeText="Discover Section Heading"
                    />
                    <BannerImageCard
                      title="Discover Section Image"
                      imageUrl={discoverSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setDiscoverSectionImageFile}
                      setImageUrl={setDiscoverSectionImageUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Discover Section Sub-Heading"
                      name="detail[discoverSection][subHeading]"
                      placeText="Discover Section Sub-Heading"
                    />
                    <TextAreas
                      labelText="Discover Section Description"
                      name="detail[discoverSection][description]"
                      placeText="Discover Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Discover Section First Counter Value"
                      name="detail[discoverSection][firstCounterValue]"
                      placeText="Discover Section First Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Discover Section First Counter Text"
                      name="detail[discoverSection][firstCounterText]"
                      placeText="Discover Section First Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Discover Section Second Counter Value"
                      name="detail[discoverSection][secondCounterValue]"
                      placeText="Discover Section Second Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Discover Section Second Counter Text"
                      name="detail[discoverSection][secondCounterText]"
                      placeText="Discover Section Second Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Discover Section Third Counter Value"
                      name="detail[discoverSection][thirdCounterValue]"
                      placeText="Discover Section Third Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Discover Section Third Counter Text"
                      name="detail[discoverSection][thirdCounterText]"
                      placeText="Discover Section Third Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Discover Section Forth Counter Value"
                      name="detail[discoverSection][forthCounterValue]"
                      placeText="Discover Section Forth Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Discover Section Forth Counter Text"
                      name="detail[discoverSection][forthCounterText]"
                      placeText="Discover Section Forth Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Discover Section Button Text"
                      name="detail[discoverSection][buttonText]"
                      placeText="Discover Section Button Text"
                    />
                    {/* quickly Job Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Quickly Job Section Heading"
                      name="detail[quicklyJobSection][heading]"
                      placeText="Quickly Job Section Heading"
                    />
                    <TextAreas
                      labelText="Quickly Section Description"
                      name="detail[quicklyJobSection][description]"
                      placeText="Quickly Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText=" Job Section Button Text"
                      name="detail[quicklyJobSection][buttonText]"
                      placeText="Quickly Job Section Button Text"
                    />
                    {/* Meet Providers section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Meet Our Providers Section Heading"
                      name="detail[meetOurProvidersSection][heading]"
                      placeText="Meet Our Providers Section Heading"
                    />
                    <TextAreas
                      labelText="Meet Our Providers Section Description"
                      name="detail[meetOurProvidersSection][description]"
                      placeText="Meet Our Providers Section Description"
                    />
                    <BannerImageCard
                      title="Meet Our Providers Section Section First Provider Thumbnail"
                      imageUrl={meetProviderSectionFirstProviderThumbnailUrl}
                      isBlogImage={true}
                      setImageFile={setMeetProviderSectionFirstProviderThumbnailFile}
                      setImageUrl={setMeetProviderSectionFirstProviderThumbnailUrl}
                      imageText="Change Image"
                    />
                    <label>Meet Our Providers Section Section First Provider Video *</label>
                    <VideoCard
                      width={400} height={300}
                      videoUrl={meetProviderSectionFirstProviderVideoUrl}
                      setVideoFile={setMeetProviderSectionFirstProviderVideoFile}
                      setVideoUrl={setMeetProviderSectionFirstProviderVideoUrl}
                    />
                    <br />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Meet Our Providers Section First Provider Name"
                      name="detail[meetOurProvidersSection][firstProviderName]"
                      placeText="Meet Our Providers Section First Provider Name"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Meet Our Providers Section First Provider Job Title"
                      name="detail[meetOurProvidersSection][firstProviderJobTitle]"
                      placeText="Meet Our Providers Section First Provider Job Title"
                    />
                    <BannerImageCard
                      title="Meet Our Providers Section Section Second Provider Thumbnail"
                      imageUrl={meetProviderSectionSecondProviderThumbnailUrl}
                      isBlogImage={true}
                      setImageFile={setMeetProviderSectionSecondProviderThumbnailFile}
                      setImageUrl={setMeetProviderSectionSecondProviderThumbnailUrl}
                      imageText="Change Image"
                    />
                    
                    <label>Meet Our Providers Section Section Second Provider Video *</label>
                    <VideoCard
                      width={400} height={300}
                      videoUrl={meetProviderSectionSecondProviderVideoUrl}
                      setVideoFile={setMeetProviderSectionSecondProviderVideoFile}
                      setVideoUrl={setMeetProviderSectionSecondProviderVideoUrl}
                    />
                    <br />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Meet Our Providers Section Second Provider Name"
                      name="detail[meetOurProvidersSection][secondProviderName]"
                      placeText="Meet Our Providers Section Second Provider Name"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Meet Our Providers Section Second Provider Job Title"
                      name="detail[meetOurProvidersSection][secondProviderJobTitle]"
                      placeText="Meet Our Providers Section Second Provider Job Title"
                    />
                    <BannerImageCard
                      title="Meet Our Providers Section Section Third Provider Thumbnail"
                      imageUrl={meetProviderSectionThirdProviderThumbnailUrl}
                      isBlogImage={true}
                      setImageFile={setMeetProviderSectionThirdProviderThumbnailFile}
                      setImageUrl={setMeetProviderSectionThirdProviderThumbnailUrl}
                      imageText="Change Image"
                    />
                    
                    <label>Meet Our Providers Section Section Third Provider Video *</label>
                    <VideoCard
                      width={400} height={300}
                      videoUrl={meetProviderSectionThirdProviderVideoUrl}
                      setVideoFile={setMeetProviderSectionThirdProviderVideoFile}
                      setVideoUrl={setMeetProviderSectionThirdProviderVideoUrl}
                    />
                    <br />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Meet Our Providers Section Third Provider Name"
                      name="detail[meetOurProvidersSection][thirdProviderName]"
                      placeText="Meet Our Providers Section Third Provider Name"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Meet Our Providers Section Third Provider Job Title"
                      name="detail[meetOurProvidersSection][thirdProviderJobTitle]"
                      placeText="Meet Our Providers Section Third Provider Job Title"
                    />                  
                    <BannerImageCard
                      title="Meet Our Providers Section Section Fourth Provider Thumbnail"
                      imageUrl={meetProviderSectionFourthProviderThumbnailUrl}
                      isBlogImage={true}
                      setImageFile={setMeetProviderSectionFourthProviderThumbnailFile}
                      setImageUrl={setMeetProviderSectionFourthProviderThumbnailUrl}
                      imageText="Change Image"
                    />
                    
                    <label>Meet Our Providers Section Section Fourth Provider Video *</label>
                    <VideoCard
                      width={400} height={300}
                      videoUrl={meetProviderSectionFourthProviderVideoUrl}
                      setVideoFile={setMeetProviderSectionFourthProviderVideoFile}
                      setVideoUrl={setMeetProviderSectionFourthProviderVideoUrl}
                    />
                    <br />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Meet Our Providers Section Fourth Provider Name"
                      name="detail[meetOurProvidersSection][fourthProviderName]"
                      placeText="Meet Our Providers Section Fourth Provider Name"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Meet Our Providers Section Fourth Provider Job Title"
                      name="detail[meetOurProvidersSection][fourthProviderJobTitle]"
                      placeText="Meet Our Providers Section Fourth Provider Job Title"
                    />

                    {/* Why Choose section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Why Choose Section Section Heading"
                      name="detail[whyChooseSection][heading]"
                      placeText="Why Choose Section Section Heading"
                    />
                    <TextAreas
                      labelText="Why Choose Section Section Description"
                      name="detail[whyChooseSection][description]"
                      placeText="Why Choose Section Section Description"
                    />

                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Why Choose Section First Sub-Heading"
                      name="detail[whyChooseSection][firstSubHeading]"
                      placeText="Why Choose Section First Sub-Heading"
                    />
                    <TextAreas
                      labelText="Why Choose Section First Sub-Description"
                      name="detail[whyChooseSection][firstSubDescription]"
                      placeText="Why Choose Section First Sub-Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Why Choose Section Second Sub-Heading"
                      name="detail[whyChooseSection][secondSubHeading]"
                      placeText="Why Choose Section Second Sub-Heading"
                    />
                    <TextAreas
                      labelText="Why Choose Section Second Sub-Description"
                      name="detail[whyChooseSection][secondSubDescription]"
                      placeText="Why Choose Section Second Sub-Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Why Choose Section Third Sub-Heading"
                      name="detail[whyChooseSection][thirdSubHeading]"
                      placeText="Why Choose Section Third Sub-Heading"
                    />
                    <TextAreas
                      labelText="Why Choose Section Third Sub-Description"
                      name="detail[whyChooseSection][thirdSubDescription]"
                      placeText="Why Choose Section Third Sub-Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Choose Section Button Text"
                      name="detail[whyChooseSection][buttonText]"
                      placeText="Choose Section Button Text"
                    />
                    <BannerImageCard
                      title="Why Choose Section Image"
                      imageUrl={whyChooseSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setWhyChooseSectionImageFile}
                      setImageUrl={setWhyChooseSectionImageUrl}
                      imageText="Change Image"
                    />

                    {/* Testimonial section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Testimonials Section Heading"
                      name="detail[testimonialsSection][heading]"
                      placeText="Testimonials Section Heading"
                    />
                    <TextAreas
                      labelText="Testimonials Section Description"
                      name="detail[testimonialsSection][description]"
                      placeText="Testimonials Section Description"
                    />
                   
                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>
              </Form>
            </div>
          </div>
        </HomeWrapped>
      )}
    </>
  );
};

export default HomeContentPage;
