import { Form } from "antd";
import React, { useEffect, useState } from "react";
import BaseInput from "../../components/form/BaseInput";
import { ContentWrapped } from "./style";
import SaveButton from "../../components/button/saveButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getAboutPageContentRequest,
  postAboutPageContentRequest
} from "../../redux/contentManagementSlice";
import Loading from "../../components/shared/GeneralComponents";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";
import VideoCard from "../../components/shared/VideoCard";

const AboutPage = () => {
  const dispatch = useDispatch();
  const { aboutPageData, aboutPageStatus, aboutPageError } = useSelector(
    (state) => state.contentManagement
  );
  const [form] = Form.useForm();

  // Banner Section
  const [bannerSectionImageUrl, setBannerSectionImageUrl] = useState();
  const [bannerSectionImageFile, setBannerSectionImageFile] = useState();
  // Stats Section Images
  const [statsSectionImageUrl, setStatsSectionImageUrl] = useState();
  const [statsSectionImageFile, setStatsSectionImageFile] = useState();
  // About Section Images
  const [worksSectionFirstCardImageUrl, setWorksSectionFirstCardImageUrl] = useState();
  const [worksSectionFirstCardImageFile, setWorksSectionFirstCardImageFile] = useState();
  const [worksSectionSecondCardImageUrl, setWorksSectionSecondCardImageUrl] = useState();
  const [worksSectionSecondCardImageFile, setWorksSectionSecondCardImageFile] = useState();
  const [worksSectionThirdCardImageUrl, setWorksSectionThirdCardImageUrl] = useState();
  const [worksSectionThirdCardImageFile, setWorksSectionThirdCardImageFile] = useState();
  // Power Section Images
  const [powerSectionImageUrl, setPowerSectionImageUrl] = useState();
  const [powerSectionImageFile, setPowerSectionImageFile] = useState();
  // Commitment Section Images
  const [commitmentSectionImageUrl, setCommitmentSectionImageUrl] = useState();
  const [commitmentSectionImageFile, setCommitmentSectionImageFile] = useState();
  const [commitmentSectionFirstIconUrl, setCommitmentSectionFirstIconUrl] = useState();
  const [commitmentSectionFirstIconFile, setCommitmentSectionFirstIconFile] = useState();
  const [commitmentSectionSecondIconUrl, setCommitmentSectionSecondIconUrl] = useState();
  const [commitmentSectionSecondIconFile, setCommitmentSectionSecondIconFile] = useState();

  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  useEffect(() => {
    dispatch(getAboutPageContentRequest(CONTENT_PAGES.ABOUT_US_PAGE));
  }, [dispatch]);

  useEffect(() => {
    if (aboutPageData) {
      form.setFieldsValue({
        "detail[bannerSection][heading]": aboutPageData?.contentPage?.detail?.bannerSection?.heading,
        "detail[bannerSection][description]": aboutPageData?.contentPage?.detail?.bannerSection?.description,
        // stats section
        "detail[statsSection][heading]": aboutPageData?.contentPage?.detail?.statsSection?.heading,
        "detail[statsSection][description]": aboutPageData?.contentPage?.detail?.statsSection?.description,
        "detail[statsSection][firstCounterValue]": aboutPageData?.contentPage?.detail?.statsSection?.firstCounterValue,
        "detail[statsSection][firstCounterText]": aboutPageData?.contentPage?.detail?.statsSection?.firstCounterText,
        "detail[statsSection][secondCounterValue]": aboutPageData?.contentPage?.detail?.statsSection?.secondCounterValue,
        "detail[statsSection][secondCounterText]": aboutPageData?.contentPage?.detail?.statsSection?.secondCounterText,
        "detail[statsSection][thirdCounterValue]": aboutPageData?.contentPage?.detail?.statsSection?.thirdCounterValue,
        "detail[statsSection][thirdCounterText]": aboutPageData?.contentPage?.detail?.statsSection?.thirdCounterText,
        "detail[statsSection][forthCounterValue]": aboutPageData?.contentPage?.detail?.statsSection?.forthCounterValue,
        "detail[statsSection][forthCounterText]": aboutPageData?.contentPage?.detail?.statsSection?.forthCounterText,
         // work section
        "detail[worksSection][heading]": aboutPageData?.contentPage?.detail?.worksSection?.heading,
        "detail[worksSection][firstCardHeading]": aboutPageData?.contentPage?.detail?.worksSection?.firstCardHeading,
        "detail[worksSection][firstCardDescription]": aboutPageData?.contentPage?.detail?.worksSection?.firstCardDescription,
        "detail[worksSection][secondCardHeading]": aboutPageData?.contentPage?.detail?.worksSection?.secondCardHeading,
        "detail[worksSection][secondCardDescription]": aboutPageData?.contentPage?.detail?.worksSection?.secondCardDescription,
        "detail[worksSection][thirdCardHeading]": aboutPageData?.contentPage?.detail?.worksSection?.thirdCardHeading,
        "detail[worksSection][thirdCardDescription]": aboutPageData?.contentPage?.detail?.worksSection?.thirdCardDescription,

        // power section 
        "detail[powerSection][heading]": aboutPageData?.contentPage?.detail?.powerSection?.heading,
        "detail[powerSection][description]": aboutPageData?.contentPage?.detail?.powerSection?.description,
        "detail[powerSection][firstCardHeading]": aboutPageData?.contentPage?.detail?.powerSection?.firstCardHeading,
        "detail[powerSection][firstCardDescription]": aboutPageData?.contentPage?.detail?.powerSection?.firstCardDescription,
        "detail[powerSection][secondCardHeading]": aboutPageData?.contentPage?.detail?.powerSection?.secondCardHeading,
        "detail[powerSection][secondCardDescription]": aboutPageData?.contentPage?.detail?.powerSection?.secondCardDescription,
        "detail[powerSection][thirdCardHeading]": aboutPageData?.contentPage?.detail?.powerSection?.thirdCardHeading,
        "detail[powerSection][thirdCardDescription]": aboutPageData?.contentPage?.detail?.powerSection?.thirdCardDescription,
        "detail[powerSection][fourthCardHeading]": aboutPageData?.contentPage?.detail?.powerSection?.fourthCardHeading,
        "detail[powerSection][fourthCardDescription]": aboutPageData?.contentPage?.detail?.powerSection?.fourthCardDescription,
        // commitment Section
        "detail[commitmentSection][heading]": aboutPageData?.contentPage?.detail?.commitmentSection?.heading,
        "detail[commitmentSection][description]": aboutPageData?.contentPage?.detail?.commitmentSection?.description,
        "detail[commitmentSection][firstHeading]": aboutPageData?.contentPage?.detail?.commitmentSection?.firstHeading,
        "detail[commitmentSection][firstDescription]": aboutPageData?.contentPage?.detail?.commitmentSection?.firstDescription,
        "detail[commitmentSection][secondHeading]": aboutPageData?.contentPage?.detail?.commitmentSection?.secondHeading,
        "detail[commitmentSection][secondDescription]": aboutPageData?.contentPage?.detail?.commitmentSection?.secondDescription,
        // testimonial Section
        "detail[testimonialsSection][heading]": aboutPageData?.contentPage?.detail?.testimonialsSection?.heading,
        "detail[testimonialsSection][description]": aboutPageData?.contentPage?.detail?.testimonialsSection?.description,

        //Seo Values Set
        meta_title: aboutPageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description: aboutPageData?.contentPage?.seo_keywords?.meta_description ?? "",
        is_canonical: aboutPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
        robots_meta: aboutPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author: aboutPageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type: aboutPageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title: aboutPageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description: aboutPageData?.contentPage?.seo_keywords?.og_description ?? "",
        og_image_width: aboutPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
        og_image_height: aboutPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
        og_url: aboutPageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name: aboutPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
        og_locale: aboutPageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate: aboutPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
        twitter_card: aboutPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
        twitter_site: aboutPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
        twitter_title: aboutPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
        twitter_description: aboutPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
        twitter_url: aboutPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setBannerSectionImageUrl(aboutPageData?.contentPage?.contentImages?.bannerSectionImage ?? null);
      setStatsSectionImageUrl(aboutPageData?.contentPage?.contentImages?.statsSectionImage ?? null);
      setWorksSectionFirstCardImageUrl(aboutPageData?.contentPage?.contentImages?.worksSectionFirstCardImage ?? BannerImage);
      setWorksSectionSecondCardImageUrl(aboutPageData?.contentPage?.contentImages?.worksSectionSecondCardImage ?? BannerImage);
      setWorksSectionThirdCardImageUrl(aboutPageData?.contentPage?.contentImages?.worksSectionThirdCardImage ?? BannerImage);
      setPowerSectionImageUrl(aboutPageData?.contentPage?.contentImages?.statsSectionImage ?? BannerImage);
      setCommitmentSectionImageUrl(aboutPageData?.contentPage?.contentImages?.commitmentSectionImage ?? BannerImage);
      setStatsSectionImageUrl(aboutPageData?.contentPage?.contentImages?.statsSectionImage ?? BannerImage);
      setCommitmentSectionFirstIconUrl(aboutPageData?.contentPage?.contentImages?.commitmentSectionFirstIcon ?? BannerImage);
      setCommitmentSectionSecondIconUrl(aboutPageData?.contentPage?.contentImages?.commitmentSectionSecondIcon ?? BannerImage);
      setTwitterImageUrl(aboutPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
      setOGImageUrl(aboutPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);
    }
  }, [aboutPageData]);

  const onUpdate = (values) => {
    const data = {
      ...values,
      name: 'About Us',
      "contentImages[bannerSectionImage]": bannerSectionImageFile ?? "",
      "contentImages[statsSectionImage]": statsSectionImageFile ?? "",
      "contentImages[worksSectionFirstCardImage]": worksSectionFirstCardImageFile ?? "",
      "contentImages[worksSectionSecondCardImage]": worksSectionSecondCardImageFile ?? "",
      "contentImages[worksSectionThirdCardImage]": worksSectionThirdCardImageFile ?? "",
      "contentImages[powerSectionImage]": powerSectionImageFile ?? "",
      "contentImages[commitmentSectionImage]": commitmentSectionImageFile ?? "",
      "contentImages[commitmentSectionFirstIcon]": commitmentSectionFirstIconFile ?? "",
      "contentImages[commitmentSectionSecondIcon]": commitmentSectionSecondIconFile ?? "",
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      _method: 'PUT'
    }
    dispatch(postAboutPageContentRequest({ data, id: aboutPageData?.contentPage?.id }))
  };
  return (
    <>
      {aboutPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : aboutPageError === STATUSES.ERROR ? (
        <div>{aboutPageError}</div>
      ) : (
        <ContentWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}
              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >About Page</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Description"
                      name="detail[bannerSection][description]"
                      placeText="Banner Section Description"
                    />
                    <BannerImageCard
                      title="Banner Section Image"
                      imageUrl={bannerSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setBannerSectionImageFile}
                      setImageUrl={setBannerSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Stats Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Heading"
                      name="detail[statsSection][heading]"
                      placeText="Stats Section Heading"
                    />
                    <TextAreas
                      labelText="Stats Section Description"
                      name="detail[statsSection][description]"
                      placeText="Stats Section Description"
                    />
                    <BannerImageCard
                      title="Stats Section Image"
                      imageUrl={statsSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setStatsSectionImageFile}
                      setImageUrl={setStatsSectionImageUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section First Counter Value"
                      name="detail[statsSection][firstCounterValue]"
                      placeText="Stats Section First Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section First Counter Text"
                      name="detail[statsSection][firstCounterText]"
                      placeText="Stats Section First Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Second Counter Value"
                      name="detail[statsSection][secondCounterValue]"
                      placeText="Stats Section Second Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Second Counter Text"
                      name="detail[statsSection][secondCounterText]"
                      placeText="Stats Section Second Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Third Counter Value"
                      name="detail[statsSection][thirdCounterValue]"
                      placeText="Stats Section Third Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Third Counter Text"
                      name="detail[statsSection][thirdCounterText]"
                      placeText="Stats Section Third Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Forth Counter Value"
                      name="detail[statsSection][forthCounterValue]"
                      placeText="Stats Section Forth Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Forth Counter Text"
                      name="detail[statsSection][forthCounterText]"
                      placeText="Stats Section Forth Counter Text"
                    />
                    {/* How it works Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Works Section Heading"
                      name="detail[worksSection][heading]"
                      placeText="Works Section Heading"
                    />
                    <BannerImageCard
                      title="Works Section First Card Image"
                      imageUrl={worksSectionFirstCardImageUrl}
                      isBlogImage={true}
                      setImageFile={setWorksSectionFirstCardImageFile}
                      setImageUrl={setWorksSectionFirstCardImageUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Works Section First Card Heading"
                      name="detail[worksSection][firstCardHeading]"
                      placeText="Works Section First Card Heading"
                    />
                    <TextAreas
                      labelText="Works Section First Card Description"
                      name="detail[worksSection][firstCardDescription]"
                      placeText="Works Section First Card Description"
                    />
                    <BannerImageCard
                      title="Works Section Second Card Image"
                      imageUrl={worksSectionSecondCardImageUrl}
                      isBlogImage={true}
                      setImageFile={setWorksSectionSecondCardImageFile}
                      setImageUrl={setWorksSectionSecondCardImageUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Works Section Second Card Heading"
                      name="detail[worksSection][secondCardHeading]"
                      placeText="Works Section Second Card Heading"
                    />
                    <TextAreas
                      labelText="Works Section Second Card Description"
                      name="detail[worksSection][secondCardDescription]"
                      placeText="Works Section Second Card Description"
                    />
                    <BannerImageCard
                      title="Works Section Third Card Image"
                      imageUrl={worksSectionThirdCardImageUrl}
                      isBlogImage={true}
                      setImageFile={setWorksSectionThirdCardImageFile}
                      setImageUrl={setWorksSectionThirdCardImageUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Works Section Third Card Heading"
                      name="detail[worksSection][thirdCardHeading]"
                      placeText="Works Section Third Card Heading"
                    />
                    <TextAreas
                      labelText="Works Section Third Card Description"
                      name="detail[worksSection][thirdCardDescription]"
                      placeText="Works Section Third Card Description"
                    />

                    {/* Power Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Power Section Heading"
                      name="detail[powerSection][heading]"
                      placeText="Power Section Heading"
                    />
                    <TextAreas
                      labelText="Power Section Description"
                      name="detail[powerSection][description]"
                      placeText="Power Section Description"
                    />
                    <BannerImageCard
                      title="Power Section Image"
                      imageUrl={powerSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setPowerSectionImageFile}
                      setImageUrl={setPowerSectionImageUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Power Section First Card Heading"
                      name="detail[powerSection][firstCardHeading]"
                      placeText="Power Section First Card Heading"
                    />
                    <TextAreas
                      labelText="Power Section First Card Description"
                      name="detail[powerSection][firstCardDescription]"
                      placeText="Power Section First Card Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Power Section Second Card Heading"
                      name="detail[powerSection][secondCardHeading]"
                      placeText="Power Section Second Card Heading"
                    />
                    <TextAreas
                      labelText="Power Section Second Card Description"
                      name="detail[powerSection][secondCardDescription]"
                      placeText="Power Section Second Card Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Power Section Third Card Heading"
                      name="detail[powerSection][thirdCardHeading]"
                      placeText="Power Section Third Card Heading"
                    />
                    <TextAreas
                      labelText="Power Section Third Card Description"
                      name="detail[powerSection][thirdCardDescription]"
                      placeText="Power Section Third Card Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Power Section Fourth Card Heading"
                      name="detail[powerSection][fourthCardHeading]"
                      placeText="Power Section Fourth Card Heading"
                    />
                    <TextAreas
                      labelText="Power Section Fourth Card Description"
                      name="detail[powerSection][fourthCardDescription]"
                      placeText="Power Section Fourth Card Description"
                    />
                    {/* Commitment Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Commitment Section Heading"
                      name="detail[commitmentSection][heading]"
                      placeText="Commitment Section Heading"
                    />
                    <TextAreas
                      labelText="Commitment Section Description"
                      name="detail[commitmentSection][description]"
                      placeText="Commitment Section Description"
                    />
                    <BannerImageCard
                      title="Commitment Section First Icon"
                      imageUrl={commitmentSectionFirstIconUrl}
                      isBlogImage={true}
                      setImageFile={setCommitmentSectionFirstIconFile}
                      setImageUrl={setCommitmentSectionFirstIconUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Commitment Section First Heading"
                      name="detail[commitmentSection][firstHeading]"
                      placeText="Commitment Section First Heading"
                    />
                    <TextAreas
                      labelText="Commitment Section First Description"
                      name="detail[commitmentSection][firstDescription]"
                      placeText="Commitment Section First Description"
                    />
                    <BannerImageCard
                      title="Commitment Section Second Icon"
                      imageUrl={commitmentSectionSecondIconUrl}
                      isBlogImage={true}
                      setImageFile={setCommitmentSectionSecondIconFile}
                      setImageUrl={setCommitmentSectionSecondIconUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Commitment Section Second Heading"
                      name="detail[commitmentSection][secondHeading]"
                      placeText="Commitment Section Second Heading"
                    />
                    <TextAreas
                      labelText="Commitment Section Second Description"
                      name="detail[commitmentSection][secondDescription]"
                      placeText="Commitment Section Second Description"
                    />
                    <BannerImageCard
                      title="Commitment Section Side Image"
                      imageUrl={commitmentSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setCommitmentSectionImageFile}
                      setImageUrl={setCommitmentSectionImageUrl}
                      imageText="Change Image"
                    />

                    {/* Testimonials Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Testimonials Section Heading"
                      name="detail[testimonialsSection][heading]"
                      placeText="Testimonials Section Heading"
                    />
                    <TextAreas
                      labelText="Testimonials Section Description"
                      name="detail[testimonialsSection][description]"
                      placeText="Testimonials Section Description"
                    />

                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>

              </Form>
            </div>
          </div>
        </ContentWrapped>
      )}
    </>
  );
};

export default AboutPage;
