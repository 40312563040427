import { Form } from "antd";
import React, { useEffect, useState, } from "react";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import { HomeWrapped } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  getWhyWorkLocumTenensPageContentRequest,
  postWhyWorkLocumTenensPageContentRequest,
} from "../../redux/contentManagementSlice";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";
import VideoCard from "../../components/shared/VideoCard";

const WhyWorkLocumTenensPage = () => {

  const dispatch = useDispatch();
  const { whyWorkLocumTenensPageData, whyWorkLocumTenensPageStatus, whyWorkLocumTenensPageError } =
    useSelector((state) => state.contentManagement);
  const [form] = Form.useForm();

  const [bannerSectionImageUrl, setBannerSectionImageUrl] = useState();
  const [bannerSectionImageFile, setBannerSectionImageFile] = useState();
  // Locum Tenens Section
  const [locumTenensSectionImageUrl, setLocumTenensSectionImageUrl] = useState();
  const [locumTenensSectionImageFile, setLocumTenensSectionImageFile] = useState();
  // Reason Section Cards
  const [whoWorkLocumMainImageUrl, setWhoWorkLocumMainImageUrl] = useState();
  const [whoWorkLocumMainImageFile, setWhoWorkLocumMainImageFile] = useState();
  const [possibilitiesSectionFirstCardIconUrl, setPossibilitiesSectionFirstCardIconUrl] = useState();
  const [possibilitiesSectionFirstCardIconFile, setPossibilitiesSectionFirstCardIconFile] = useState();
  const [possibilitiesSectionSecondCardIconUrl, setPossibilitiesSectionSecondCardIconUrl] = useState();
  const [possibilitiesSectionSecondCardIconFile, setPossibilitiesSectionSecondCardIconFile] = useState();
  const [possibilitiesSectionThirdCardIconUrl, setPossibilitiesSectionThirdCardIconUrl] = useState();
  const [possibilitiesSectionThirdCardIconFile, setPossibilitiesSectionThirdCardIconFile] = useState();
  const [possibilitiesSectionFourthCardIconUrl, setPossibilitiesSectionFourthCardIconUrl] = useState();
  const [possibilitiesSectionFourthCardIconFile, setPossibilitiesSectionFourthCardIconFile] = useState();
  const [possibilitiesSectionFifthCardIconUrl, setPossibilitiesSectionFifthCardIconUrl] = useState();
  const [possibilitiesSectionFifthCardIconFile, setPossibilitiesSectionFifthCardIconFile] = useState();
  const [possibilitiesSectionSixCardIconUrl, setPossibilitiesSectionSixCardIconUrl] = useState();
  const [possibilitiesSectionSixCardIconFile, setPossibilitiesSectionSixCardIconFile] = useState();
  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  useEffect(() => {
    dispatch(getWhyWorkLocumTenensPageContentRequest(CONTENT_PAGES.WHY_WORK_LOCUM_TENENS_PAGE));
  }, [dispatch]);

  useEffect(() => {
    if (whyWorkLocumTenensPageData) {
      form.setFieldsValue({
        "detail[bannerSection][heading]": whyWorkLocumTenensPageData?.contentPage?.detail?.bannerSection?.heading,
        "detail[bannerSection][subHeading]": whyWorkLocumTenensPageData?.contentPage?.detail?.bannerSection?.subHeading,
        "detail[bannerSection][description]": whyWorkLocumTenensPageData?.contentPage?.detail?.bannerSection?.description,
         /* Advantage Section */
         "detail[advantageSection][heading]": whyWorkLocumTenensPageData?.contentPage?.detail?.advantageSection?.heading,
        "detail[advantageSection][description]": whyWorkLocumTenensPageData?.contentPage?.detail?.advantageSection?.description,
          /* Possibilities Section */
        "detail[possibilitiesSection][heading]": whyWorkLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.heading,
        "detail[possibilitiesSection][description]": whyWorkLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.description,
        "detail[possibilitiesSection][firstCardHeading]": whyWorkLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.firstCardHeading,
        "detail[possibilitiesSection][firstCardDescription]": whyWorkLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.firstCardDescription,
        "detail[possibilitiesSection][secondCardHeading]": whyWorkLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.secondCardHeading,
        "detail[possibilitiesSection][secondCardDescription]": whyWorkLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.secondCardDescription,
        "detail[possibilitiesSection][thirdCardHeading]": whyWorkLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.thirdCardHeading,
        "detail[possibilitiesSection][thirdCardDescription]": whyWorkLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.thirdCardDescription,
        "detail[possibilitiesSection][fourthCardHeading]": whyWorkLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.fourthCardHeading,
        "detail[possibilitiesSection][fourthCardDescription]": whyWorkLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.fourthCardDescription,
        "detail[possibilitiesSection][fifthCardHeading]": whyWorkLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.fifthCardHeading,
        "detail[possibilitiesSection][fifthCardDescription]": whyWorkLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.fifthCardDescription,
        "detail[possibilitiesSection][sixCardHeading]": whyWorkLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.sixCardHeading,
        "detail[possibilitiesSection][sixCardDescription]": whyWorkLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.sixCardDescription,
        // howWorkLocumTenens Section
        "detail[howWorkLocumTenensSection][heading]": whyWorkLocumTenensPageData?.contentPage?.detail?.howWorkLocumTenensSection?.heading,
        "detail[howWorkLocumTenensSection][description]": whyWorkLocumTenensPageData?.contentPage?.detail?.howWorkLocumTenensSection?.description,
        "detail[howWorkLocumTenensSection][firstBulletPoint]": whyWorkLocumTenensPageData?.contentPage?.detail?.howWorkLocumTenensSection?.firstBulletPoint,
        "detail[howWorkLocumTenensSection][secondBulletPoint]": whyWorkLocumTenensPageData?.contentPage?.detail?.howWorkLocumTenensSection?.secondBulletPoint,
        "detail[howWorkLocumTenensSection][thirdBulletPoint]": whyWorkLocumTenensPageData?.contentPage?.detail?.howWorkLocumTenensSection?.thirdBulletPoint,
        "detail[howWorkLocumTenensSection][forthBulletPoint]": whyWorkLocumTenensPageData?.contentPage?.detail?.howWorkLocumTenensSection?.forthBulletPoint,
          ///////////Faqs Section ///////////
        "detail[faqsSection][heading]": whyWorkLocumTenensPageData?.contentPage?.detail?.faqsSection?.heading,
        "detail[faqsSection][description]": whyWorkLocumTenensPageData?.contentPage?.detail?.faqsSection?.description,
        "detail[faqsSection][firstQuestion]": whyWorkLocumTenensPageData?.contentPage?.detail?.faqsSection?.firstQuestion,
        "detail[faqsSection][firstAnswer]": whyWorkLocumTenensPageData?.contentPage?.detail?.faqsSection?.firstAnswer,
        "detail[faqsSection][secondQuestion]": whyWorkLocumTenensPageData?.contentPage?.detail?.faqsSection?.secondQuestion,
        "detail[faqsSection][secondAnswer]": whyWorkLocumTenensPageData?.contentPage?.detail?.faqsSection?.secondAnswer,
        "detail[faqsSection][thirdQuestion]": whyWorkLocumTenensPageData?.contentPage?.detail?.faqsSection?.thirdQuestion,
        "detail[faqsSection][thirdAnswer]": whyWorkLocumTenensPageData?.contentPage?.detail?.faqsSection?.thirdAnswer,
        "detail[faqsSection][forthQuestion]": whyWorkLocumTenensPageData?.contentPage?.detail?.faqsSection?.forthQuestion,
        "detail[faqsSection][forthAnswer]": whyWorkLocumTenensPageData?.contentPage?.detail?.faqsSection?.forthAnswer,
        "detail[faqsSection][fifthQuestion]": whyWorkLocumTenensPageData?.contentPage?.detail?.faqsSection?.fifthQuestion,
        "detail[faqsSection][fifthAnswer]": whyWorkLocumTenensPageData?.contentPage?.detail?.faqsSection?.fifthAnswer,
        "detail[faqsSection][sixQuestion]": whyWorkLocumTenensPageData?.contentPage?.detail?.faqsSection?.sixQuestion,
        "detail[faqsSection][sixAnswer]": whyWorkLocumTenensPageData?.contentPage?.detail?.faqsSection?.sixAnswer,
        //Seo Values Set
        meta_title: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.meta_description ?? "",
        is_canonical: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
        robots_meta: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.og_description ?? "",
        og_image_width: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
        og_image_height: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
        og_url: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
        og_locale: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
        twitter_card: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
        twitter_site: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
        twitter_title: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
        twitter_description: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
        twitter_url: whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setBannerSectionImageUrl(whyWorkLocumTenensPageData?.contentPage?.contentImages?.bannerSectionImage ?? BannerImage);
      setWhoWorkLocumMainImageUrl(whyWorkLocumTenensPageData?.contentPage?.contentImages?.whoWorkLocumMainImage ?? BannerImage);
      setLocumTenensSectionImageUrl(whyWorkLocumTenensPageData?.contentPage?.contentImages?.locumTenensSectionImage ?? BannerImage);
      setPossibilitiesSectionFirstCardIconUrl(whyWorkLocumTenensPageData?.contentPage?.contentImages?.possibilitiesSectionFirstCardIcon ?? BannerImage);
      setPossibilitiesSectionSecondCardIconUrl(whyWorkLocumTenensPageData?.contentPage?.contentImages?.possibilitiesSectionSecondCardIcon ?? BannerImage);
      setPossibilitiesSectionThirdCardIconUrl(whyWorkLocumTenensPageData?.contentPage?.contentImages?.possibilitiesSectionThirdCardIcon ?? BannerImage);
      setPossibilitiesSectionFourthCardIconUrl(whyWorkLocumTenensPageData?.contentPage?.contentImages?.possibilitiesSectionFourthCardIcon ?? BannerImage);
      setPossibilitiesSectionFifthCardIconUrl(whyWorkLocumTenensPageData?.contentPage?.contentImages?.possibilitiesSectionFifthCardIcon ?? BannerImage);
      setPossibilitiesSectionSixCardIconUrl(whyWorkLocumTenensPageData?.contentPage?.contentImages?.possibilitiesSectionSixthCardIcon ?? BannerImage);
      setTwitterImageUrl(whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
      setOGImageUrl(whyWorkLocumTenensPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);

    }
  }, [whyWorkLocumTenensPageData]);

  const onUpdate = (values) => {

    const data = {
      ...values,
      name: 'Why Work Locum Tenens',
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      "contentImages[bannerSectionImage]": bannerSectionImageFile ?? "",
      "contentImages[whoWorkLocumMainImage]": whoWorkLocumMainImageFile ?? "",
      "contentImages[locumTenensSectionImage]": locumTenensSectionImageFile ?? "",
      "contentImages[possibilitiesSectionFirstCardIcon]": possibilitiesSectionFirstCardIconFile ?? "",
      "contentImages[possibilitiesSectionSecondCardIcon]": possibilitiesSectionSecondCardIconFile ?? "",
      "contentImages[possibilitiesSectionThirdCardIcon]": possibilitiesSectionThirdCardIconFile ?? "",
      "contentImages[possibilitiesSectionFourthCardIcon]": possibilitiesSectionFourthCardIconFile ?? "",
      "contentImages[possibilitiesSectionFifthCardIcon]": possibilitiesSectionFifthCardIconFile ?? "",
      "contentImages[possibilitiesSectionSixCardIcon]": possibilitiesSectionSixCardIconFile ?? "",
      _method: 'PUT'
    }
    dispatch(postWhyWorkLocumTenensPageContentRequest({ data, id: whyWorkLocumTenensPageData?.contentPage?.id }))
  };

  return (
    <>
      {whyWorkLocumTenensPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : whyWorkLocumTenensPageStatus === STATUSES.ERROR ? (
        <div>{whyWorkLocumTenensPageError}</div>
      ) : (
        <HomeWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}

              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >Why Work Locum Tenens</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <TextAreas
                      labelText="Banner Section Description"
                      name="detail[bannerSection][description]"
                      placeText="Banner Section Description"
                    />
                    <BannerImageCard
                      title="Banner Section Image"
                      imageUrl={bannerSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setBannerSectionImageFile}
                      setImageUrl={setBannerSectionImageUrl}
                      imageText="Change Image"
                    />

                    {/* Advantage Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Advantage Section Heading"
                      name="detail[advantageSection][heading]"
                      placeText="Advantage Section Heading"
                    />
                    <TextAreas
                      labelText="Advantage Section Description"
                      name="detail[advantageSection][description]"
                      placeText="Advantage Section Description"
                    />

                
                    {/* Possibilities Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Possibilities Section Text"
                      name="detail[possibilitiesSection][text]"
                      placeText="Possibilities Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Possibilities Section Heading"
                      name="detail[possibilitiesSection][heading]"
                      placeText="Possibilities Section Heading"
                    />
                    <TextAreas
                      labelText="Possibilities Section Description"
                      name="detail[possibilitiesSection][description]"
                      placeText="Possibilities Section Description"
                    />
                    <BannerImageCard
                      title="Possibilities Section First Card Icon"
                      imageUrl={possibilitiesSectionFirstCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setPossibilitiesSectionFirstCardIconFile}
                      setImageUrl={setPossibilitiesSectionFirstCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Possibilities Section First Card Heading"
                      name="detail[possibilitiesSection][firstCardHeading]"
                      placeText="Possibilities Section First Card Heading"
                    />
                    <TextAreas
                      labelText="Possibilities Section First Card Description"
                      name="detail[possibilitiesSection][firstCardDescription]"
                      placeText="Possibilities Section First Card Description"
                    />
                    <BannerImageCard
                      title="Possibilities Section Second Card Icon"
                      imageUrl={possibilitiesSectionSecondCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setPossibilitiesSectionSecondCardIconFile}
                      setImageUrl={setPossibilitiesSectionSecondCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Possibilities Section Second Card Heading"
                      name="detail[possibilitiesSection][secondCardHeading]"
                      placeText="Possibilities Section Second Card Heading"
                    />
                    <TextAreas
                      labelText="Possibilities Section Second Card Description"
                      name="detail[possibilitiesSection][secondCardDescription]"
                      placeText="Possibilities Section Second Card Description"
                    />
                    <BannerImageCard
                      title="Possibilities Section Third Card Icon"
                      imageUrl={possibilitiesSectionThirdCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setPossibilitiesSectionThirdCardIconFile}
                      setImageUrl={setPossibilitiesSectionThirdCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Possibilities Section Third Card Heading"
                      name="detail[possibilitiesSection][thirdCardHeading]"
                      placeText="Possibilities Section Third Card Heading"
                    />
                    <TextAreas
                      labelText="Possibilities Section Third Card Description"
                      name="detail[possibilitiesSection][thirdCardDescription]"
                      placeText="Possibilities Section Third Card Description"
                    />
                    <BannerImageCard
                      title="Possibilities Section Fourth Card Icon"
                      imageUrl={possibilitiesSectionFourthCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setPossibilitiesSectionFourthCardIconFile}
                      setImageUrl={setPossibilitiesSectionFourthCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Possibilities Section Fourth Card Heading"
                      name="detail[possibilitiesSection][fourthCardHeading]"
                      placeText="Possibilities Section Fourth Card Heading"
                    />
                    <TextAreas
                      labelText="Possibilities Section Fourth Card Description"
                      name="detail[possibilitiesSection][fourthCardDescription]"
                      placeText="Possibilities Section Fourth Card Description"
                    />
                    <BannerImageCard
                      title="Possibilities Section Fifth Card Icon"
                      imageUrl={possibilitiesSectionFifthCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setPossibilitiesSectionFifthCardIconFile}
                      setImageUrl={setPossibilitiesSectionFifthCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Possibilities Section Fifth Card Heading"
                      name="detail[possibilitiesSection][fifthCardHeading]"
                      placeText="Possibilities Section Fifth Card Heading"
                    />
                    <TextAreas
                      labelText="Possibilities Section Fifth Card Description"
                      name="detail[possibilitiesSection][fifthCardDescription]"
                      placeText="Possibilities Section Fifth Card Description"
                    />
                    <BannerImageCard
                      title="Possibilities Section Six Card Icon"
                      imageUrl={possibilitiesSectionSixCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setPossibilitiesSectionSixCardIconFile}
                      setImageUrl={setPossibilitiesSectionSixCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Possibilities Section Six Card Heading"
                      name="detail[possibilitiesSection][sixCardHeading]"
                      placeText="Possibilities Section Six Card Heading"
                    />
                    <TextAreas
                      labelText="Possibilities Section Six Card Description"
                      name="detail[possibilitiesSection][sixCardDescription]"
                      placeText="Possibilities Section Six Card Description"
                    />
                    {/* Who Work Locum Tenens Section */}
                    <BannerImageCard
                      title="Who Work Locum Tenens Section Main Image"
                      imageUrl={whoWorkLocumMainImageUrl}
                      isBlogImage={true}
                      setImageFile={setWhoWorkLocumMainImageFile}
                      setImageUrl={setWhoWorkLocumMainImageUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Who Work Locum Tenens Section Heading"
                      name="detail[howWorkLocumTenensSection][heading]"
                      placeText="Who Work Locum Tenens Section Heading"
                    />
                    <TextAreas
                      labelText="Who Work Locum Tenens Section Description"
                      name="detail[howWorkLocumTenensSection][description]"
                      placeText="Who Work Locum Tenens Section Description"
                    />
                   
                      <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="How Locum Tenens Section First Bullet Point"
                      name="detail[howWorkLocumTenensSection][firstBulletPoint]"
                      placeText="How Locum Tenens Section First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="How Locum Tenens Section Second Bullet Point"
                      name="detail[howWorkLocumTenensSection][secondBulletPoint]"
                      placeText="How Locum Tenens Section Second Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="How Locum Tenens Section Third Bullet Point"
                      name="detail[howWorkLocumTenensSection][thirdBulletPoint]"
                      placeText="How Locum Tenens Section Third Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="How Locum Tenens Section Forth Bullet Point"
                      name="detail[howWorkLocumTenensSection][forthBulletPoint]"
                      placeText="How Locum Tenens Section Forth Bullet Point"
                    />
                      {/* FAQS Section */}
                      <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Heading"
                      name="detail[faqsSection][heading]"
                      placeText="FAQS Section Heading"
                    />
                    <TextAreas
                      labelText="FAQS Section Description"
                      name="detail[faqsSection][description]"
                      placeText="FAQS Section Description"
                    />                  
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section First Question"
                      name="detail[faqsSection][firstQuestion]"
                      placeText="FAQS Section First Question"
                    />
                    <TextAreas
                      labelText="FAQS Section First Answer"
                      name="detail[faqsSection][firstAnswer]"
                      placeText="FAQS Section First Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Second Question"
                      name="detail[faqsSection][secondQuestion]"
                      placeText="FAQS Section Second Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Second Answer"
                      name="detail[faqsSection][secondAnswer]"
                      placeText="FAQS Section Second Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Third Question"
                      name="detail[faqsSection][thirdQuestion]"
                      placeText="FAQS Section Third Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Third Answer"
                      name="detail[faqsSection][thirdAnswer]"
                      placeText="FAQS Section Third Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Forth Question"
                      name="detail[faqsSection][forthQuestion]"
                      placeText="FAQS Section Forth Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Forth Answer"
                      name="detail[faqsSection][forthAnswer]"
                      placeText="FAQS Section Forth Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Fifth Question"
                      name="detail[faqsSection][fifthQuestion]"
                      placeText="FAQS Section Fifth Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Fifth Answer"
                      name="detail[faqsSection][fifthAnswer]"
                      placeText="FAQS Section Fifth Answer"
                    />
                     <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Six Question"
                      name="detail[faqsSection][sixQuestion]"
                      placeText="FAQS Section Six Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Six Answer"
                      name="detail[faqsSection][sixAnswer]"
                      placeText="FAQS Section Six Answer"
                    />
                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>
              </Form>
            </div>
          </div>
        </HomeWrapped>
      )}
    </>
  );
};

export default WhyWorkLocumTenensPage;
