import React from "react";
import { SideBarTabWrapped } from "./style";

const SideBarTab = () => {
  return (
    <>
      <SideBarTabWrapped>
        <div className="row">
          <div className="col-lg-3 col-12">
            <div className="side-tabs d-lg-flex flex-column">
              <div
                className="nav nav_side_tab nav-pills"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <button
                  className="nav-link active col"
                  id="v-pills-home-tab"
                  aria-controls="v-pills-home"
                  data-bs-target="#v-pills-home"
                  data-bs-toggle="pill"
                  type="button"
                  role="tab"
                  aria-selected="true"
                >
                  <span> Home</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-why-work-locum-tenens-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-why-work-locum-tenens"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-why-work-locum-tenens"
                  aria-selected="false"
                >
                  <span>Why Work Locum Tenens?</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-locum-tenens-overview-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-locum-tenens-overview"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-locum-tenens-overview"
                  aria-selected="false"
                >
                  <span>Locum Tenens Overview</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-about-us-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-about-us"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-about-us"
                  aria-selected="false"
                >
                  <span>About Us</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-job-list-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-job-list"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-job-list"
                  aria-selected="false"
                >
                  <span>Job List</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-contact"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-contact"
                  aria-selected="false"
                >
                  <span>Contact Page</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-login-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-login"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-login"
                  aria-selected="false"
                >
                  <span>Login Page</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-register-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-register"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-register"
                  aria-selected="false"
                >
                  <span>Register Page</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-forgot-password-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-forgot-password"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-forgot-password"
                  aria-selected="false"
                >
                  <span>Forgot Password Page</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-reset-password-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-reset-password"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-reset-password"
                  aria-selected="false"
                >
                  <span>Reset Password Page</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-success-reset-password-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-success-reset-password"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-success-reset-password"
                  aria-selected="false"
                >
                  <span>Success Reset Password Page</span>
                </button>
                {/* <button
              className="nav-link"
              id="v-pills-messages-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-messages"
              type="button"
              role="tab"
              aria-controls="v-pills-messages"
              aria-selected="false"
            >
              <span>About Us</span>
            </button> */}
                <button
                  className="nav-link col"
                  id="terms-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#terms"
                  type="button"
                  role="tab"
                  aria-controls="terms"
                  aria-selected="false"
                >
                  <span>Terms & Conditions</span>
                </button>
                <button
                  className="nav-link"
                  id="privacy-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#privacy"
                  type="button"
                  role="tab"
                  aria-controls="privacy"
                  aria-selected="false"
                >
                  <span>Privacy Policy</span>
                </button>
                <button
                  className="nav-link"
                  id="cookie-policy-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#cookie-policy"
                  type="button"
                  role="tab"
                  aria-controls="cookie-policy"
                  aria-selected="false"
                >
                  <span>Cookie Policy</span>
                </button>
                <button
                  className="nav-link"
                  id="footer-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#footer"
                  type="button"
                  role="tab"
                  aria-controls="footer"
                  aria-selected="false"
                >
                  <span>Web Site Footer</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </SideBarTabWrapped>
    </>
  );
};
export default SideBarTab;
