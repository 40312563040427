import { Form } from "antd";
import React, { useEffect, useState, } from "react";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import { HomeWrapped } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  getLocumTenensOverviewPageContentRequest,
  postLocumTenensOverviewPageContentRequest,
} from "../../redux/contentManagementSlice";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";

const LocumTenensOverview = () => {

  const dispatch = useDispatch();
  const { locumTenensOverviewPageData, locumTenensOverviewPageStatus, locumTenensOverviewPageError } =
    useSelector((state) => state.contentManagement);
  const [form] = Form.useForm();

  const [bannerSectionImageUrl, setBannerSectionImageUrl] = useState();
  const [bannerSectionImageFile, setBannerSectionImageFile] = useState();
  // Reason Section Cards
  const [benefitsSectionMainImageUrl, setBenefitsSectionMainImageUrl] = useState();
  const [benefitsSectionMainImageFile, setBenefitsSectionMainImageFile] = useState();
  const [benefitsSectionFirstCardIconUrl, setBenefitsSectionFirstCardIconUrl] = useState();
  const [benefitsSectionFirstCardIconFile, setBenefitsSectionFirstCardIconFile] = useState();
  const [benefitsSectionSecondCardIconUrl, setBenefitsSectionSecondCardIconUrl] = useState();
  const [benefitsSectionSecondCardIconFile, setBenefitsSectionSecondCardIconFile] = useState();
  const [benefitsSectionThirdCardIconUrl, setBenefitsSectionThirdCardIconUrl] = useState();
  const [benefitsSectionThirdCardIconFile, setBenefitsSectionThirdCardIconFile] = useState();
  const [benefitsSectionForthCardIconUrl, setBenefitsSectionForthCardIconUrl] = useState();
  const [benefitsSectionForthCardIconFile, setBenefitsSectionForthCardIconFile] = useState();
  const [benefitsSectionFifthCardIconUrl, setBenefitsSectionFifthCardIconUrl] = useState();
  const [benefitsSectionFifthCardIconFile, setBenefitsSectionFifthCardIconFile] = useState();
  const [benefitsSectionSixthCardIconUrl, setBenefitsSectionSixthCardIconUrl] = useState();
  const [benefitsSectionSixthCardIconFile, setBenefitsSectionSixthCardIconFile] = useState();
  // How Locum Tenens Work Section Cards
  const [howLocumTenensWorkSectionFirstCardIconUrl, setHowLocumTenensWorkSectionFirstCardIconUrl] = useState();
  const [howLocumTenensWorkSectionFirstCardIconFile, setHowLocumTenensWorkSectionFirstCardIconFile] = useState();
  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  useEffect(() => {
    dispatch(getLocumTenensOverviewPageContentRequest(CONTENT_PAGES.LOCUM_TENENS_OVERVIEW_PAGE));
  }, [dispatch]);

  useEffect(() => {
    if (locumTenensOverviewPageData) {
      form.setFieldsValue({
        "detail[bannerSection][heading]": locumTenensOverviewPageData?.contentPage?.detail?.bannerSection?.heading,
        "detail[bannerSection][description]": locumTenensOverviewPageData?.contentPage?.detail?.bannerSection?.description,
        // benefit Section  
        "detail[benefitsSection][heading]": locumTenensOverviewPageData?.contentPage?.detail?.benefitsSection?.heading,
        "detail[benefitsSection][description]": locumTenensOverviewPageData?.contentPage?.detail?.benefitsSection?.description,
        "detail[benefitsSection][firstCardHeading]": locumTenensOverviewPageData?.contentPage?.detail?.benefitsSection?.firstCardHeading,
        "detail[benefitsSection][firstCardDescription]": locumTenensOverviewPageData?.contentPage?.detail?.benefitsSection?.firstCardDescription,
        "detail[benefitsSection][secondCardHeading]": locumTenensOverviewPageData?.contentPage?.detail?.benefitsSection?.secondCardHeading,
        "detail[benefitsSection][secondCardDescription]": locumTenensOverviewPageData?.contentPage?.detail?.benefitsSection?.secondCardDescription,
        "detail[benefitsSection][thirdCardHeading]": locumTenensOverviewPageData?.contentPage?.detail?.benefitsSection?.thirdCardHeading,
        "detail[benefitsSection][thirdCardDescription]": locumTenensOverviewPageData?.contentPage?.detail?.benefitsSection?.thirdCardDescription,
        "detail[benefitsSection][forthCardHeading]": locumTenensOverviewPageData?.contentPage?.detail?.benefitsSection?.forthCardHeading,
        "detail[benefitsSection][forthCardDescription]": locumTenensOverviewPageData?.contentPage?.detail?.benefitsSection?.forthCardDescription,
        // Can Do Section
        "detail[canDoSection][heading]": locumTenensOverviewPageData?.contentPage?.detail?.canDoSection?.heading,
        "detail[canDoSection][description]": locumTenensOverviewPageData?.contentPage?.detail?.canDoSection?.description,
        "detail[canDoSection][firstSubHeading]": locumTenensOverviewPageData?.contentPage?.detail?.canDoSection?.firstSubHeading,
        "detail[canDoSection][firstSubDescription]": locumTenensOverviewPageData?.contentPage?.detail?.canDoSection?.firstSubDescription,
        "detail[canDoSection][secondSubHeading]": locumTenensOverviewPageData?.contentPage?.detail?.canDoSection?.secondSubHeading,
        "detail[canDoSection][secondSubDescription]": locumTenensOverviewPageData?.contentPage?.detail?.canDoSection?.secondSubDescription,
        "detail[canDoSection][thirdSubHeading]": locumTenensOverviewPageData?.contentPage?.detail?.canDoSection?.thirdSubHeading,
        "detail[canDoSection][thirdSubDescription]": locumTenensOverviewPageData?.contentPage?.detail?.canDoSection?.thirdSubDescription,
        // how locumTenens Work
        "detail[howLocumTenensWorkSection][heading]": locumTenensOverviewPageData?.contentPage?.detail?.howLocumTenensWorkSection?.heading,
        "detail[howLocumTenensWorkSection][firstCardHeading]": locumTenensOverviewPageData?.contentPage?.detail?.howLocumTenensWorkSection?.firstCardHeading,
        "detail[howLocumTenensWorkSection][firstCardDescription]": locumTenensOverviewPageData?.contentPage?.detail?.howLocumTenensWorkSection?.firstCardDescription,
        "detail[howLocumTenensWorkSection][secondCardHeading]": locumTenensOverviewPageData?.contentPage?.detail?.howLocumTenensWorkSection?.secondCardHeading,
        "detail[howLocumTenensWorkSection][secondCardDescription]": locumTenensOverviewPageData?.contentPage?.detail?.howLocumTenensWorkSection?.secondCardDescription,
        "detail[howLocumTenensWorkSection][thirdCardHeading]": locumTenensOverviewPageData?.contentPage?.detail?.howLocumTenensWorkSection?.thirdCardHeading,
        "detail[howLocumTenensWorkSection][thirdCardDescription]": locumTenensOverviewPageData?.contentPage?.detail?.howLocumTenensWorkSection?.thirdCardDescription,
      // Testimonial Section
      "detail[testimonialsSection][heading]": locumTenensOverviewPageData?.contentPage?.detail?.testimonialsSection?.heading,
      "detail[testimonialsSection][description]": locumTenensOverviewPageData?.contentPage?.detail?.testimonialsSection?.description,
        //Seo Values Set
        meta_title: locumTenensOverviewPageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description: locumTenensOverviewPageData?.contentPage?.seo_keywords?.meta_description ?? "",
        is_canonical: locumTenensOverviewPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
        robots_meta: locumTenensOverviewPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author: locumTenensOverviewPageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type: locumTenensOverviewPageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title: locumTenensOverviewPageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description: locumTenensOverviewPageData?.contentPage?.seo_keywords?.og_description ?? "",
        og_image_width: locumTenensOverviewPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
        og_image_height: locumTenensOverviewPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
        og_url: locumTenensOverviewPageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name: locumTenensOverviewPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
        og_locale: locumTenensOverviewPageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate: locumTenensOverviewPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
        twitter_card: locumTenensOverviewPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
        twitter_site: locumTenensOverviewPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
        twitter_title: locumTenensOverviewPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
        twitter_description: locumTenensOverviewPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
        twitter_url: locumTenensOverviewPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setBannerSectionImageUrl(locumTenensOverviewPageData?.contentPage?.contentImages?.bannerSectionImage ?? BannerImage);
      setBenefitsSectionMainImageUrl(locumTenensOverviewPageData?.contentPage?.contentImages?.benefitsSectionMainImage ?? BannerImage);
      setBenefitsSectionFirstCardIconUrl(locumTenensOverviewPageData?.contentPage?.contentImages?.benefitsSectionFirstCardIcon ?? BannerImage);
      setBenefitsSectionSecondCardIconUrl(locumTenensOverviewPageData?.contentPage?.contentImages?.benefitsSectionSecondCardIcon ?? BannerImage);
      setBenefitsSectionThirdCardIconUrl(locumTenensOverviewPageData?.contentPage?.contentImages?.benefitsSectionThirdCardIcon ?? BannerImage);
      setBenefitsSectionForthCardIconUrl(locumTenensOverviewPageData?.contentPage?.contentImages?.benefitsSectionForthCardIcon ?? BannerImage);
      setBenefitsSectionFifthCardIconUrl(locumTenensOverviewPageData?.contentPage?.contentImages?.benefitsSectionFifthCardIcon ?? BannerImage);
      setBenefitsSectionSixthCardIconUrl(locumTenensOverviewPageData?.contentPage?.contentImages?.benefitsSectionSixthCardIcon ?? BannerImage);
      setHowLocumTenensWorkSectionFirstCardIconUrl(locumTenensOverviewPageData?.contentPage?.contentImages?.howLocumTenensWorkSectionFirstCardIcon ?? BannerImage);
     
      setTwitterImageUrl(locumTenensOverviewPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
      setOGImageUrl(locumTenensOverviewPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);
    }
  }, [locumTenensOverviewPageData]);

  const onUpdate = (values) => {

    const data = {
      ...values,
      name: 'Locum Tenens Overview',
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      "contentImages[bannerSectionImage]": bannerSectionImageFile ?? "",
      "contentImages[benefitsSectionMainImage]": benefitsSectionMainImageFile ?? "",
      "contentImages[benefitsSectionFirstCardIcon]": benefitsSectionFirstCardIconFile ?? "",
      "contentImages[benefitsSectionSecondCardIcon]": benefitsSectionSecondCardIconFile ?? "",
      "contentImages[benefitsSectionThirdCardIcon]": benefitsSectionThirdCardIconFile ?? "",
      "contentImages[benefitsSectionForthCardIcon]": benefitsSectionForthCardIconFile ?? "",
      "contentImages[benefitsSectionFifthCardIcon]": benefitsSectionFifthCardIconFile ?? "",
      "contentImages[benefitsSectionSixthCardIcon]": benefitsSectionSixthCardIconFile ?? "",
      "contentImages[howLocumTenensWorkSectionFirstCardIcon]": howLocumTenensWorkSectionFirstCardIconFile ?? "",
      
      _method: 'PUT'
    }
    dispatch(postLocumTenensOverviewPageContentRequest({ data, id: locumTenensOverviewPageData?.contentPage?.id }))
  };

  return (
    <>
      {locumTenensOverviewPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : locumTenensOverviewPageStatus === STATUSES.ERROR ? (
        <div>{locumTenensOverviewPageError}</div>
      ) : (
        <HomeWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}

              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >What Locum Tenens</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                 
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <TextAreas
                      labelText="Banner Section Description"
                      name="detail[bannerSection][description]"
                      placeText="Banner Section Description"
                    />
                    <BannerImageCard
                      title="Banner Section Image"
                      imageUrl={bannerSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setBannerSectionImageFile}
                      setImageUrl={setBannerSectionImageUrl}
                      imageText="Change Image"
                    />
                       
                    
                    {/* Benefits Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefits Section Text"
                      name="detail[benefitsSection][text]"
                      placeText="Benefits Section Text"
                    />
                    <BannerImageCard
                      title="Benefits Section Main Image"
                      imageUrl={benefitsSectionMainImageUrl}
                      isBlogImage={true}
                      setImageFile={setBenefitsSectionMainImageFile}
                      setImageUrl={setBenefitsSectionMainImageUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefits Section Heading"
                      name="detail[benefitsSection][heading]"
                      placeText="Benefits Section Heading"
                    />
                    <TextAreas
                      labelText="Benefits Section Description"
                      name="detail[benefitsSection][description]"
                      placeText="Benefits Section Description"
                    />
                    <BannerImageCard
                      title="Benefits Section First Card Icon"
                      imageUrl={benefitsSectionFirstCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setBenefitsSectionFirstCardIconFile}
                      setImageUrl={setBenefitsSectionFirstCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefits Section First Card Heading"
                      name="detail[benefitsSection][firstCardHeading]"
                      placeText="Benefits Section First Card Heading"
                    />
                    <TextAreas
                      labelText="Benefits Section First Card Description"
                      name="detail[benefitsSection][firstCardDescription]"
                      placeText="Benefits Section First Card Description"
                    />
                    <BannerImageCard
                      title="Benefits Section Second Card Icon"
                      imageUrl={benefitsSectionSecondCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setBenefitsSectionSecondCardIconFile}
                      setImageUrl={setBenefitsSectionSecondCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefits Section Second Card Heading"
                      name="detail[benefitsSection][secondCardHeading]"
                      placeText="Benefits Section Second Card Heading"
                    />
                    <TextAreas
                      labelText="Benefits Section Second Card Description"
                      name="detail[benefitsSection][secondCardDescription]"
                      placeText="Benefits Section Second Card Description"
                    />
                    <BannerImageCard
                      title="Benefits Section Third Card Icon"
                      imageUrl={benefitsSectionThirdCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setBenefitsSectionThirdCardIconFile}
                      setImageUrl={setBenefitsSectionThirdCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefits Section Third Card Heading"
                      name="detail[benefitsSection][thirdCardHeading]"
                      placeText="Benefits Section Third Card Heading"
                    />
                    <TextAreas
                      labelText="Benefits Section Third Card Description"
                      name="detail[benefitsSection][thirdCardDescription]"
                      placeText="Benefits Section Third Card Description"
                    />
                    <BannerImageCard
                      title="Benefits Section Forth Card Icon"
                      imageUrl={benefitsSectionForthCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setBenefitsSectionForthCardIconFile}
                      setImageUrl={setBenefitsSectionForthCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefits Section Forth Card Heading"
                      name="detail[benefitsSection][forthCardHeading]"
                      placeText="Benefits Section Forth Card Heading"
                    />
                    <TextAreas
                      labelText="Benefits Section Forth Card Description"
                      name="detail[benefitsSection][forthCardDescription]"
                      placeText="Benefits Section Forth Card Description"
                    />
                 
                    {/* Can Do Section */}

                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Can Do Section Heading"
                      name="detail[canDoSection][heading]"
                      placeText="Can Do Section Heading"
                    />
                    <TextAreas
                      labelText="Can Do Section Description"
                      name="detail[canDoSection][description]"
                      placeText="Can Do Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Can Do Section First Sub-Heading"
                      name="detail[canDoSection][firstSubHeading]"
                      placeText="Can Do Section First Sub-Heading"
                    />
                    <TextAreas
                      labelText="Can Do Section First Sub-Description"
                      name="detail[canDoSection][firstSubDescription]"
                      placeText="Can Do Section First Sub-Description"
                    />
                     <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Can Do Section Second Sub-Heading"
                      name="detail[canDoSection][secondSubHeading]"
                      placeText="Can Do Section Second Sub-Heading"
                    />
                    <TextAreas
                      labelText="Can Do Section Second Sub-Description"
                      name="detail[canDoSection][secondSubDescription]"
                      placeText="Can Do Section Second Sub-Description"
                    />
                     <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Can Do Section Third Sub-Heading"
                      name="detail[canDoSection][thirdSubHeading]"
                      placeText="Can Do Section Third Sub-Heading"
                    />
                    <TextAreas
                      labelText="Can Do Section Third Sub-Description"
                      name="detail[canDoSection][thirdSubDescription]"
                      placeText="Can Do Section Third Sub-Description"
                    />
                    
                    {/* How Locum Tenens Work Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="How Locum Tenens Work Section Heading"
                      name="detail[howLocumTenensWorkSection][heading]"
                      placeText="How Locum Tenens Work Section Heading"
                    />
                    <BannerImageCard
                      title="How Locum Tenens Work Section First Card Icon"
                      imageUrl={howLocumTenensWorkSectionFirstCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setHowLocumTenensWorkSectionFirstCardIconFile}
                      setImageUrl={setHowLocumTenensWorkSectionFirstCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="How Locum Tenens Work Section First Card Heading"
                      name="detail[howLocumTenensWorkSection][firstCardHeading]"
                      placeText="How Locum Tenens Work Section First Card Heading"
                    />
                    <TextAreas
                     labelText="How Locum Tenens Work Section First Card Description"
                     name="detail[howLocumTenensWorkSection][firstCardDescription]"
                     placeText="How Locum Tenens Work Section First Card Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="How Locum Tenens Work Section Second Card Heading"
                      name="detail[howLocumTenensWorkSection][secondCardHeading]"
                      placeText="How Locum Tenens Work Section Second Card Heading"
                    />
                    <TextAreas
                     labelText="How Locum Tenens Work Section Second Card Description"
                     name="detail[howLocumTenensWorkSection][secondCardDescription]"
                     placeText="How Locum Tenens Work Section Second Card Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="How Locum Tenens Work Section Third Card Heading"
                      name="detail[howLocumTenensWorkSection][thirdCardHeading]"
                      placeText="How Locum Tenens Work Section Third Card Heading"
                    />
                    <TextAreas
                     labelText="How Locum Tenens Work Section Third Card Description"
                     name="detail[howLocumTenensWorkSection][thirdCardDescription]"
                     placeText="How Locum Tenens Work Section Third Card Description"
                    />
                   

                      {/* Testimonial section */}
                      <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Testimonials Section Heading"
                      name="detail[testimonialsSection][heading]"
                      placeText="Testimonials Section Heading"
                    />
                    <TextAreas
                      labelText="Testimonials Section Description"
                      name="detail[testimonialsSection][description]"
                      placeText="Testimonials Section Description"
                    />

                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>
              </Form>
            </div>
          </div>
        </HomeWrapped>
      )}
    </>
  );
};

export default LocumTenensOverview;
